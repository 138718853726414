<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3 pl-1">
        <v-row>
          <v-col>
            <site-header
              backRouteName="dashboard"
              :backText="$_t('backText')"
              :title="$_t('title')"
              :sub-title="$_t('subTitle')"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <main class="pt-8">
      <List class="wa__large" style="min-height: 600px">
        <div>
          <div class="pending__box">
            <div class="d-flex justify-center mb-10">
              <div class="pending">
                <i class="WMi-clock-1"></i>
              </div>
            </div>
            <div class="pl-0 pl-md-16">
              <div class="title" v-html="$_t('requestSend')"></div>
              <div class="text-left text">
                <div>{{ $_t("requestSendText1") }}</div>
                <div>{{ $_t("requestSendText2") }}</div>
              </div>
              <div class="finish mt-6 px-4" v-html="$_t('requestSendFinish')"></div>
              <div class="text-left mt-7">
                <router-link
                  class="text-decoration-none"
                  :to="{
                    name: 'dashboard',
                    params: { locale: this.$_getlocaleParam() },
                  }"
                >
                  <block-button
                    :text="$_t('editTheRequest')"
                    height="30"
                    text-mode="text"
                    icon="WMi-left-chevron"
                    class="btn-edit btn__size__18 px-0"
                  ></block-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </List>
    </main>
  </div>
</template>
<script>
import List from "../components/Global/Section/List.vue";
export default {
  components: {
    List,
  },
};
</script>
<style scoped>
.pending__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pending {
  width: 200px;
  height: 200px;
  background-color: #f1f1f179;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pending i {
  font-size: 90px;
  color: var(--color-black);
}
.finish {
  color: var(--color-smoky);
  font-family: "Montserrat-light";
  font-size: 18px;
}
.title {
  color: var(--color-black);
  font-family: "Montserrat-regular" !important;
  letter-spacing: 1px !important;
  font-size: 27px !important;
  text-align: left;
}
.title b {
  color: var(--color-cyan);
  font-family: "Montserrat-bold" !important;
}
.text {
  font-family: "Montserrat-regular" !important;
  font-size: 24px;
}
.btn-edit {
  font-family: "Montserrat-light";
  letter-spacing: 2px !important;
}
@media screen and (max-width: 960px) {
  .pending__box {
    position: sticky;
    top: 0;
    left: 0;
    transform: none;
    margin: 100px 0;
  }
}
</style>
