import { render, staticRenderFns } from "./RequestSended.vue?vue&type=template&id=5ac85ed5&scoped=true&"
import script from "./RequestSended.vue?vue&type=script&lang=js&"
export * from "./RequestSended.vue?vue&type=script&lang=js&"
import style0 from "./RequestSended.vue?vue&type=style&index=0&id=5ac85ed5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ac85ed5",
  null
  
)

export default component.exports